import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorHandlingService } from '@shared/validation/services/error-handling.service';

@Injectable()
export class LogValidationErrorInterceptor implements HttpInterceptor {

   constructor(private errorHandlingService: ErrorHandlingService) { }

   public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
         tap(event => { }, error => {

            if (req.url.indexOf('error/catch') > -1) {
               console.error('Error calling errorHandlingService.');
               return;
            }

            if (error instanceof HttpErrorResponse && (error?.status === 400 || error?.status === 403 || error?.status === 500)) {
               return;
            }

            //only log unknow error instance or code
            this.errorHandlingService.logError(error);
         })
      );
   }
}
