<ngx-loading-bar [height]="'3px'" [color]="'#FFC434'" [includeSpinner]="true" [diameter]="'18px'"></ngx-loading-bar>

<div class="app-container">
   <div class="header d-flex justify-content-between">
      <div class="logo-img"></div>

      <div class="header-actions d-flex" *ngIf="authService.customerName && !isOriginPage && !authService.isOriginSsoAuthenticated">
         <div ngbDropdown class="dropdown-userinfo d-inline-block" [placement]="'bottom-right'">
            <button type="button" class="btn btn-light" id="dropdownUserinfo" ngbDropdownToggle>
               <telenet-icon name="user"></telenet-icon>
               <span class="user-info-text">{{authService.customerName}}</span>
               <telenet-icon class="chevron-down" name="chevron-down"></telenet-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownUserinfo">
               <div class="user-info">
                  <div class="user-label">
                     <div class="label">{{'USERNAME' | translate}}</div>
                     <div>{{authService.username}}</div>
                  </div>
                  <div class="user-label">
                     <div class="label">{{'BCKOFF_INVOICINGDETAILS_CUSTOMERNR' | translate}}</div>
                     <div>{{authService.userId}}</div>
                  </div>
               </div>
               <button type="button" ngbDropdownItem class="my-2" [routerLink]="'/ng/account/overview'">
                  <span> {{'BCKOFF_MNU_HOME' | translate}} </span>
               </button>
               <button type="button" class="btn btn-telenet btn-logout" (click)="logout()">{{'LOG_OUT' | translate}}</button>
            </div>
         </div>
         <div ngbDropdown class="dropdown-languages d-inline-block">
            <button type="button" class="btn btn-light" id="dropdownLanguages" ngbDropdownToggle>
               <span>{{selectedLanguage}}</span>
               <telenet-icon class="chevron-down" name="chevron-down"></telenet-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownLanguages">
               <button type="button" ngbDropdownItem [class.selected]="selectedLanguage ==='NL'" (click)="changeLanguage('N')">NL</button>
               <button type="button" ngbDropdownItem [class.selected]="selectedLanguage ==='FR'" (click)="changeLanguage('F')">FR</button>
               <button type="button" ngbDropdownItem [class.selected]="selectedLanguage ==='EN'" (click)="changeLanguage('E')">EN</button>
            </div>
         </div>
      </div>
   </div>
   <div class="content" *ngIf="authService.customerName || isOriginPage; else maintenanceRouterOutlet">
      <div class="navigation" *ngIf="!isOriginPage && !authService.isOriginSsoAuthenticated">
         <my-cloud-navigation></my-cloud-navigation>
      </div>
      <div class="container-fluid" [class.animate]="isLoading">
         <router-outlet *ngIf="!isLoading"></router-outlet>
      </div>
   </div>
   <div class="footer">
      <span [innerHTML]="'BCKOFF_MYACCOUNT_FOOTER' | translate"></span>
   </div>
</div>


<ng-template #maintenanceRouterOutlet>
   <router-outlet></router-outlet>
</ng-template>
