import { Injectable } from '@angular/core';
import { LoggingService } from '../../../core/services/logging.service';
import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ErrorCommand } from '@shared/validation/models/error-command.interface';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { AppSettings } from 'app/app.settings';
import { noResponseToastHeaders } from '@shared/constants';

@Injectable({
   providedIn: 'root'
})
export class ErrorHandlingService {
   constructor(private http: HttpClient, private loggingService: LoggingService, private locationStrategy: LocationStrategy, private appSettings: AppSettings) { }

   public logError(error: any): void {
      const message = error.message ? error.message : error.toString();

      // Log to frontend
      this.loggingService.error(message);

      // Log to backend
      const command = {
         message,
         fileName: this.locationStrategy.path(),
         stackTrace: error.toString()
      } as ErrorCommand;

      this.http.put<any>(`${this.appSettings.baseCoreApiUrl}/error/catch`, command, { headers: noResponseToastHeaders }).pipe(
         catchError(err => {
            console.error('Handling error locally and rethrowing it...', err);
            return EMPTY;
         })
      ).subscribe();
   }
}
